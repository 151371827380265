import { Button, Tooltip, CircularProgress } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectedLanguageSelector, setSelectedLanguage, } from "app/rkt_query/SettingsReducer";
import { decryptStoredData } from "app/pages/storetoken/StoreTokenCheck";
import { useGetCurrentRestaurantQuery } from "app/rkt_query/storeApis";
import { Config } from "constant";
import LanguageIcon from "@mui/icons-material/Language";

const commonStyles = {
  border: 1,
  width: "4rem",
  height: "4rem",
  maxWidth: "40px",
  maxHeight: "40px",
  minWidth: "40px",
  minHeight: "40px",
  borderRadius: "50%",
};

const LanguageSwitcher = () => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(selectedLanguageSelector);

  const restaurantId = localStorage.getItem(Config.restaurant_id);
  const { data, error, isLoading } = useGetCurrentRestaurantQuery(restaurantId);

  console.log("Fetched data:", data?.data);

  const languages = useMemo(() => data?.data?.restaurant?.languages ?? [], [data]);

  useEffect(() => {
    if (languages.length > 0 && !selectedLanguage) {
      dispatch(setSelectedLanguage(languages[0].language_symbol));
    }
  }, [languages, dispatch, selectedLanguage]);

  if (isLoading) { return <CircularProgress size={24} /> }

  if (error) {
    return (
      <Tooltip title="Error fetching languages">
        <LanguageIcon color="error" />
      </Tooltip>
    ); 
  }

  return (
    <div style={{ display: "flex", gap: 5 }}>
      {languages.length > 0 ? (
        languages.map((lang) => (
          <Button
            key={lang.id}
            sx={commonStyles}
            size="small"
            variant={
              selectedLanguage === lang.language_symbol
                ? "contained"
                : "outlined"
            }
            onClick={() => dispatch(setSelectedLanguage(lang.language_symbol))}
          >
            {lang.language_symbol.toUpperCase()}
          </Button>
        ))
      ) : (
        <Tooltip title="No languages available">
          <LanguageIcon color="disabled" />
        </Tooltip>
      )}
    </div>
  );
};

export default LanguageSwitcher;
