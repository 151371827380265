import React from 'react';
import { Grid } from "@mui/material";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from 'app/layouts/layouts';
import OrderChart from './../charts/OrderChart';
import SaleChart from './../charts/SaleChart';
import CustomerChart from './../charts/CustomerChart';
import { selectedUserLoginSelector } from 'app/rkt_query/SelectedStuffReducer';
import { useSelector } from 'react-redux';

const Dashboard = () => {
    const { setActiveLayout } = useJumboApp();
    const selectedUserLogin = useSelector(selectedUserLoginSelector);
    const [hasReloaded, setHasReloaded] = React.useState(false);

    React.useEffect(() => {
        setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
        if (selectedUserLogin && !hasReloaded) {
            window.location.reload(false);
            setHasReloaded(true);
        }
    }, [selectedUserLogin, hasReloaded]);

    const orderData = [
        { name: 'Average Revenue', amount: 4000, fill: '#8884d8' },
        { name: 'Total Revenue', amount: 3000, fill: '#83a6ed' },
        { name: 'Average Orders', amount: 2000, fill: '#8dd1e1' },
        { name: 'Total Orders', amount: 2780, fill: '#82ca9d' }
    ];

    return (
        <>
            <h1>Orders</h1><br></br>
            <Grid container spacing={3.75}>
                <OrderChart data={orderData} />
            </Grid><br></br>

            <h1>Sales</h1><br></br>
            <Grid container spacing={3.75}>
                <SaleChart />
            </Grid><br></br>

            <h1>New Customers</h1><br></br>
            <Grid container spacing={3.75}>
                <CustomerChart />
            </Grid>
        </>
    );
};

export default Dashboard;
