import { selectedProductSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetProductQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { Config } from 'constant';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewProduct = ({ selectId }) => {

    const selectedProduct = useSelector(selectedProductSelector);
    const [ProductId, setProductId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingProduct, error: errorProduct } = useGetProductQuery(ProductId);
    const product = res_get?.data?.product;
    const [selectedFile, setSelectedFile] = useState(product?.image);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    useEffect(() => {
        product?.image && setSelectedFile(product?.image)
        setProductId(selectedProduct ? selectedProduct?.id : selectId)
    }, [])
    const productArray =
        [
            { label: 'Name', data: product?.title[selectedLanguage] },
            { label: 'Category', data: product?.menu?.name[selectedLanguage] },
            { label: 'Size', data: product?.size_group?.short_title },
            { label: 'Price', data: product?.price },
            { label: 'Deal', data: product?.deal },
            { label: 'Stars', data: product?.stars },
            { label: 'cooking_time', data: product?.cooking_time },
            { label: 'State', data: product?.state },
            { label: 'Detail', data: product?.detail[selectedLanguage] },

        ]
    const { hideDialog } = useJumboDialog();
    return (
        <div>

            {errorProduct ? <div>{JSON.stringify(errorProduct)}</div>
                : isLoadingProduct ? <div>Loading Single Product.....</div>
                    : product ?
                        <DZGenericView obj={productArray} imagePath={Config.digitalOceanLinkProductSmallImg} image={product?.image} checkImage={true} closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewProduct

