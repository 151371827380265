import React from "react";

import ConfigRouteGuard from "app/pages/auth/ConfigRouteGuard";
import Admin from "../pages/admin";
import User from "../pages/user";
import Login2 from "../pages/auth/login2/index"
import Dashboard from "app/pages/dashboard/Dashboard";
import Login from './../pages/auth/login/Login';
// import UserProfile from './../pages/users/user-profile/UserProfile';
import ForgotPassword from './../pages/auth/forgot-password/ForgotPassword';
import ForgotPasswordCode from './../pages/auth/forgot-password-code/ForgotPasswordCode';
import ChangePassword from './../pages/auth/forgot-password-code/ChangePassword';
import Profile from './../pages/profile/index';
import CategoryList from './../pages/category/index';
import ProductList from './../pages/product/index';
import AccessoryList from './../pages/accessory/index';
import RestaurantList from './../pages/restaurant/index';
import UpdateViewRestaurantInfo from './../pages/restaurant-info-time/UpdateViewRestaurantInfo';
import UpdateViewRestaurantTime from './../pages/restaurant-info-time/UpdateViewRestaurantTime';
import SizeGroupList from "app/pages/size_group";
import SizeList from "app/pages/size";
import ProductAccessoriesList from './../pages/product_accessory/index';
import OrderList from './../pages/order Sequence/index';
import Orders from './../pages/order/index';
import TableOrder from '../pages/tableOrder/index.js';
import { adminCondition, adminManagerCondition, adminOwnerCondition, adminOwnerManagerCondition, showConfigurations } from "app/layouts/shared/sidebars/Sidebar/menus";
import PoscAdminList from "app/pages/PoscAdmin";
import RestaurantTableList from "app/pages/restaurant_table";
import UpdateViewRestaurantFeatureInfo from "app/pages/restaurant-info-time/UpdateViewRestaurantFeature";
import ContactList from "app/pages/contact";
import OrderDetail from "app/pages/order-detail";
import DeliveryPostCodeList from "app/pages/delivery_post_code";
import AddUpdateOwnerCard from "app/pages/restaurant-info-time/AddUpdateOwnerCard";
import GalleryList from "app/pages/gallery";
import MediaRoom from "app/pages/media_room";
import RestaurantListForSuperAdmin from "app/pages/auth/restaurant-list";
import LoginSuperAdmin from "app/pages/auth/login-super-admin";

const routes = [

    { path: "/", element: <Login /> },
    { path: "/login-super-admin", element: <LoginSuperAdmin /> },
    { path: "forgot-password",element: <ForgotPassword />  },
    { path: "verify-forgot-password", element: <ForgotPasswordCode /> },
    { path: "change-password", element: <ChangePassword />},
    { path: "/login", element: <Login2 />},
    { path: "/profile",element: <Profile />},
    { path: "/restaurant-list-for-super-admin",  element: <RestaurantListForSuperAdmin />},
    { path: "/dashboard",  element: <Dashboard />},
    { path: "/order-table",element: <TableOrder /> },
    { path: "/order-list",element: <OrderList /> },
    { path: "/order-detail",element: <OrderDetail /> },
      adminOwnerManagerCondition()&& { path: "/product-accessories",element: <ProductAccessoriesList />},
      adminOwnerManagerCondition()&&{ path: "/orders", element: <Orders />},
      adminOwnerManagerCondition()&&{ path: "/categories",element: <CategoryList />},
      adminOwnerManagerCondition()&&{ path: "/products", element: <ProductList /> },
      adminOwnerManagerCondition()&&{ path: "/accessories",element: <AccessoryList />},
      adminOwnerManagerCondition()&&{ path: "/size-groups",element: <SizeGroupList />},
      adminOwnerManagerCondition()&&{ path: "/gallery",element: <GalleryList />},
      adminOwnerManagerCondition()&&{ path: "/media-room",element: <MediaRoom />},
      adminOwnerManagerCondition()&&{ path: "/sizes", element: <SizeList /> },
      adminOwnerManagerCondition()&&{ path: "/restaurants", element: <RestaurantList />},
      adminOwnerManagerCondition()&&{ path: "/restaurant-table",element: <RestaurantTableList />},
      adminOwnerManagerCondition()&&{ path: "restaurant-owner-card",element: <AddUpdateOwnerCard />},
      adminOwnerManagerCondition()&&{ path: "/restaurant-post-code",element: <DeliveryPostCodeList />},
      adminOwnerManagerCondition()&&{ path: "/restaurant-info",element: <UpdateViewRestaurantInfo />},
      adminOwnerManagerCondition()&&{ path: "/restaurant-time", element: <UpdateViewRestaurantTime /> },
      
      adminOwnerCondition()&&{path: "/admins", element: <Admin /> },
      adminOwnerCondition()&&{path: "/contacts", element: <ContactList /> },
      adminOwnerCondition()&&{path: "/posc", element: <PoscAdminList /> },
      adminOwnerCondition()&& { path: "/users",element: <User />},
      
    // ...(showConfigurations()?[
      { path: "/restaurant-feature",element:(<ConfigRouteGuard><UpdateViewRestaurantFeatureInfo /></ConfigRouteGuard>)},
    // ]:[])
].filter(Boolean); //Remove falsy values

export default routes;