import React from 'react'
import { TextField } from '@mui/material';
import Div from '@jumbo/shared/Div/Div';
import Grid from "@mui/material/Grid";
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { useSelector } from 'react-redux';
const DZTextField = ({ sx3mb = "", sx3mt = "", sx3mr = "", sx3ml = "", col = 12, sx2 = 12, label, noOfRows = 1, sx = '', maxRows = 3, error, variant = "outlined", value = "", type = "text", onChange = (e) => { } }) => {

  const selectedLanguage = useSelector(selectedLanguageSelector);
  const getValue = () => typeof value === 'object' ? value[selectedLanguage] : value;

  return (
    <Grid item xs={sx2} md={col} lg={col}>
      <Div sx={{ mb: sx3mb, mt: sx3mt, mr: sx3mr, ml: sx3ml }}>
        <TextField 
          fullWidth id="outlined-basic" sx={sx} type={type} label={label} {...(error && { error: true, helperText: error })} variant={variant} 
          value={getValue()} onChange={onChange} multiline maxRows={maxRows} minRows={noOfRows} 
        />
      </Div>
    </Grid>
  )
}
// fullWidth
export default DZTextField
export const DZDateField = ({ label, noOfRows = 1, sx = '', error, variant = "outlined", value = "", type = "date", onChange = (e) => { } }) => {
  return (
    <TextField 
      fullWidth id="outlined-basic" sx={sx} type={type} label={label} {...(error && { error: true, helperText: error })} variant={variant} 
      value={value} onChange={onChange} multiline maxRows={3} minRows={noOfRows} 
    />
  )
}