import React, { useState } from "react";
import { Card, CardContent, Checkbox, FormControlLabel, IconButton, Typography, } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import shadows from "@mui/material/styles/shadows";
import { alpha } from "@mui/material/styles";
import { auth } from "@jumbo/services/auth/firebase/firebase";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useAuthSignInWithEmailAndPassword } from "@react-query-firebase/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import { ASSET_AVATARS, ASSET_IMAGES } from "../../../utils/constants/paths";
import { Config } from "./../../../../constant/index";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useGetMeQuery, useUserLoginMutation } from "app/rkt_query/storeApis";
import { useDispatch, useSelector } from "react-redux";
import { selectedUserLoginSelector, setSelectedUserLogin, } from "app/rkt_query/SelectedStuffReducer";
import { decryptStoredData, encryptStoredData, } from "app/pages/storetoken/StoreTokenCheck";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const Login = () => {
  const dispatch = useDispatch();
  const { data: userInfo } = useGetMeQuery();

  const { setActiveLayout } = useJumboApp();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [userLogin, userLoginResponse] = useUserLoginMutation();
  const selectedUserLogin = useSelector(selectedUserLoginSelector);

  const navigate = useNavigate();

  React.useEffect(() => {
    // const decrypt_token = localStorage.getItem(Config.adminApiTokenName)
    // const bytes = CryptoJS.AES.decrypt(decrypt_token, Config.secretPass);
    // JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    const storedToken = decryptStoredData(Config.adminApiTokenName);

    // console.log(storedToken);

    if (storedToken) {
      // console.log(" in trored token condition");

      navigate("/dashboard");
    }
    // console.log(" Out trored token condition");

    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
  }, []);
  const handleToClose = (event, reason) => {
    if ("clickaway" == reason) return;
    setOpen(false);
  };
  // const handler = async () => {
  const handler = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    userLogin(formData)
      .unwrap()
      .then((payload) => {
        dispatch(setSelectedUserLogin(payload.data?.admin?.role?.role_name?.name));
        // const encrypt_token = CryptoJS.AES.encrypt(
        //     JSON.stringify(payload.data.access_token),
        //     Config.secretPass
        //   ).toString();

        encryptStoredData(Config.setAdminRestaurant, payload.data?.admin?.restaurants?.[0]?.id);
        localStorage.setItem(Config.restaurant_id, payload.data?.admin?.restaurants?.[0]?.id??0);
        encryptStoredData(Config.adminApiTokenName, payload.data.access_token);
        encryptStoredData(
          Config.roleName,
          payload.data?.admin?.role?.role_name?.name
        );

        setLoading(false);

        navigate("/dashboard", { replace: true });
      })
      .catch((error) => {
        setLoading(false);
        setResponse(false);
        setOpen(true);
      });
  };
  const mutation = useAuthSignInWithEmailAndPassword(auth, {
    onError(error) {
      console.log(error);
    },
    onSuccess(data) {
      navigate("/", { replace: true });
    },
  });

  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleToClose}
      >
        <Alert onClose={handleToClose} severity="error" sx={{ width: "100%" }}>
          {"Wrong Credential"}
        </Alert>
      </Snackbar>
      <Div
        sx={{
          flex: 1,
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: (theme) => theme.spacing(4),
        }}
      >
        <Div sx={{ mb: 3, display: "inline-flex" }}>
          <Link href="#" underline="none" sx={{ display: "inline-flex" }}>
            {/* <img src={`${ASSET_IMAGES}/logotop.png`} alt="Jumbo React"   width="228px"/> */}
            <img
              src={`${ASSET_IMAGES}/${process.env.REACT_APP_LOGO_PATH}`}
              alt="Jumbo React"
              width="228px"
            />
          </Link>
        </Div>
        <Card sx={{ maxWidth: "100%", width: 360, mb: 4 }}>
          <Div sx={{ position: "relative", height: "200px" }}>
            <CardMedia
              component="img"
              alt="green iguana"
              height="200"
              // image={`${ASSET_IMAGES}/logotop.png`}
              // image={`${ASSET_IMAGES}/applogo.png`}
              image={`${ASSET_IMAGES}/${process.env.REACT_APP_LOGO_PATH}`}
            />
            <Div
              sx={{
                flex: 1,
                inset: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                backgroundColor: (theme) =>
                  alpha(theme.palette.common.black, 0.5),
                p: (theme) => theme.spacing(3),
              }}
            >
              <Typography
                variant={"h2"}
                sx={{
                  color: "common.white",
                  fontSize: "1.5rem",
                  mb: 0,
                }}
              >
                Sign In
              </Typography>
            </Div>
          </Div>
          <CardContent sx={{ pt: 0 }}>
            <Formik>
              <Div style={{ textAlign: "left" }} noValidate autoComplete="off">
                <Div sx={{ mb: 3, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="email"
                    label="Email"
                    onChange={(txt) => setEmail(txt.target.value)}
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    onChange={(pass) => setPassword(pass.target.value)}
                  />
                </Div>
                <Typography textAlign={"right"} variant={"body1"}>
                  {/* <Link underline="none" _blank href="admin-dzfood/forgot-password">Forgot your password?</Link> */}
                </Typography>
                <Div sx={{ mb: 1 }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Remember me"
                  />
                </Div>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  onClick={handler}
                  loading={loading}
                >
                  Login
                </LoadingButton>
                {/* {commentedCode here....} */}
              </Div>
              {/* )} */}
            </Formik>
          </CardContent>
        </Card>
        {/* {commentedCode here....} */}
      </Div>
    </>
  );
};

export default Login;
