import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import { Box, Button, TextField, Fab } from "@mui/material";
import {
  selectedRestaurantSelector,
  setSelectedRestaurant,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddRestaurantMutation,
  useGetRestaurantQuery,
  useUpdateRestaurantMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZTextField from "./../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "./../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, {
  NoHideSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import Grid from "@mui/material/Grid";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import { Config } from "constant";
import Div from "@jumbo/shared/Div";
import { dialogButtonsStyle } from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import DZDefaultImg from "app/DZcomponents/DZDefaultImg";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const AddUpdateRestaurant = ({ onClose, learning_journey_id, selectedId }) => {
  // const chatbot_types = ['scale', 'note', 'buddy'];
  const { showDialog, hideDialog } = useJumboDialog();

  const [open, setOpen] = React.useState(false);
  const [index1, setIndex] = React.useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const dispatch = useDispatch();
  const { fnShowSnackBar } = useSnackBarManager();
  const selectedRestaurant = useSelector(selectedRestaurantSelector);

  const {
    data: res_get,
    isLoading: isLoadingRestaurant,
    error: errorRestaurant,
  } = useGetRestaurantQuery(selectedRestaurant?.id);

  const [updateRestaurant, responseUpdate] = useUpdateRestaurantMutation();
  const [addRestaurant, responseAdd] = useAddRestaurantMutation();

  const bluePrint = BLUE_PRINTS.restaurant;

  const [restaurantState, setRestaurantState] = useState(null);

  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [showBackDrop, setShowBackDrop] = useState(false);

  const [disable, setDisabled] = useState([false]);
  const [imagePreview, setImagePreview] = useState("");
  const [selectedFile, setSelectedFile] = useState(
    res_get?.data?.restaurant?.image
  );

  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);
  useEffect(() => {
    res_get?.data?.restaurant?.image &&
      setSelectedFile(res_get?.data?.restaurant?.image);

    setRestaurantState(
      selectedRestaurant ? res_get?.data?.restaurant : bluePrint
    );
  }, [res_get]);

  console.log(res_get);

  const addUpdateRestaurantData = async (action) => {
    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("id", res_get?.data?.restaurant?.id);
    formData.append("title", restaurantState.title);
    formData.append("pickup_time", restaurantState.pickup_time);
    formData.append("delivery_time", restaurantState.delivery_time);
    formData.append("delivery_min_price", restaurantState.delivery_min_price);
    formData.append("delivery_distance", restaurantState.delivery_distance);
    formData.append("phone", restaurantState.phone);
    formData.append("email", restaurantState.email);
    formData.append("lat", restaurantState.lat);
    formData.append("long", restaurantState.long);
    formData.append("address", restaurantState.address);
    formData.append("stars", restaurantState.stars);
    formData.append("description", JSON.stringify(restaurantState.description));

    console.log(formData.get("name"));
    action === "add"
      ? await addRestaurant(formData)
          .unwrap()
          .then((res) => {
            console.log(res, "res");
            if (res?.data?.message) {
              fnShowSnackBar(
                res?.data?.message || "Restaurant added successfully"
              );
            } else {
              // fnShowSnackBar("Some thing went wrong", true);
            }
          })
      : updateRestaurant(formData)
          .unwrap()
          .then((res) => {
            console.log(res, "res");
            if (res?.data?.message) {
              fnShowSnackBar(
                res?.data?.message || "Restaurant added successfully"
              );
            } else {
              fnShowSnackBar("Some thing went wrong", true);
            }
          });
  };

  const filehandle = (e) => {
    console.log("file handle image", e.target.files[0]);
    if (e.target.files.length !== 0) {
      setSelectedFile(e.target.files[0]);
      // setImagePreview(URL.createObjectURL(e.target.files[0]));
      console.log("Checking", selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div>
      {/* {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />} */}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {restaurantState ? (
        <div
          style={{
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            gap: 25,
            marginBottom: 60,
          }}
        >
          <DZDefaultImg src={imagePreview} selectedFile={selectedFile} />

          {/* {imagePreview ? (
                        <img src={imagePreview} style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center" }} />
                    ) : (

                        <DZImageCircle restaurant="restaurant" image={selectedFile} link={Config.digitalOceanLinkRestaurantSmallImg} style={{
                            height: 100, width: 100, borderRadius: 50, alignSelf: "center",
                        }}
                        />

                    )} */}

          <Box
            sx={{
              display: "flex",
              paddingBottom: "0px",
              justifyContent: "center",
              paddingTop: "0px",
            }}
          >
            <label htmlFor="upload-photo">
              <input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={filehandle}
              />
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
              >
                Upload photo
              </Fab>
            </label>
          </Box>

          <Grid container spacing={3.75}>
            {/* <Grid item xs={12} md={8} lg={8}>

                            <DzNoBackgroundTranslatable title={"Learning Journey"} children={res_get1?.data?.learning_journey?.name} />

                        </Grid> */}

            <DZTextValidation
              col={6}
              sx={12}
              sx3mb={0.5}
              sx3mt={1}
              error={error?.title}
              label="Title"
              variant="outlined"
              value={restaurantState?.title}
              onChange={(e) => {
                setRestaurantState((x) => ({ ...x, title: e.target.value }));
              }}
            />
            <DZTextValidation
              col={6}
              sx={12}
              sx3mb={0.5}
              sx3mt={1}
              error={error?.email}
              label="Email"
              variant="outlined"
              value={restaurantState?.email}
              onChange={(e) => {
                setRestaurantState((x) => ({ ...x, email: e.target.value }));
              }}
            />
            <DZTextValidation
              col={6}
              sx={12}
              sx3mb={0.5}
              sx3mt={1}
              fullWidth
              label="Phone"
              type="number"
              inputProps={{ min: 1 }}
              variant="outlined"
              noOfRows={1}
              value={restaurantState?.phone}
              onChange={(e) => {
                setRestaurantState((x) => ({ ...x, phone: e.target.value }));
              }}
            />
            <DZTextValidation
              col={6}
              sx={12}
              sx3mb={0.5}
              sx3mt={1}
              error={error?.pickup_time}
              label="Pickup Time"
              variant="outlined"
              noOfRows={1}
              value={restaurantState?.pickup_time}
              onChange={(e) => {
                setRestaurantState((x) => ({
                  ...x,
                  pickup_time: e.target.value,
                }));
              }}
            />

            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZTextField
                  label="Delivery Time"
                  error={error?.delivery_time}
                  variant="outlined"
                  noOfRows={1}
                  value={restaurantState?.delivery_time}
                  onChange={(e) => {
                    setRestaurantState((x) => ({
                      ...x,
                      delivery_time: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <TextField
                  fullWidth
                  label="Delivery Min Price"
                  type="number"
                  error={error?.delivery_min_price}
                  variant="outlined"
                  noOfRows={1}
                  value={restaurantState?.delivery_min_price}
                  onChange={(e) => {
                    setRestaurantState((x) => ({
                      ...x,
                      delivery_min_price: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <TextField
                  fullWidth
                  label="Delivery Distance"
                  type="number"
                  variant="outlined"
                  noOfRows={1}
                  value={restaurantState?.delivery_distance}
                  onChange={(e) => {
                    setRestaurantState((x) => ({
                      ...x,
                      delivery_distance: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <TextField
                  fullWidth
                  label="Stars"
                  type="number"
                  variant="outlined"
                  noOfRows={1}
                  value={restaurantState?.stars}
                  onChange={(e) => {
                    setRestaurantState((x) => ({
                      ...x,
                      stars: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZTextField
                  label="Lat"
                  variant="outlined"
                  noOfRows={1}
                  value={restaurantState?.lat}
                  onChange={(e) => {
                    setRestaurantState((x) => ({ ...x, lat: e.target.value }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZTextField
                  label="Long"
                  variant="outlined"
                  noOfRows={1}
                  value={restaurantState?.long}
                  onChange={(e) => {
                    setRestaurantState((x) => ({ ...x, long: e.target.value }));
                  }}
                />
              </Div>
            </Grid>
          </Grid>

          <DZTextField
            label="Address"
            variant="outlined"
            error={error?.address}
            noOfRows={3}
            value={restaurantState?.address}
            onChange={(e) => {
              setRestaurantState((x) => ({ ...x, address: e.target.value }));
            }}
          />
          <DZTextField
            label="Description"
            variant="outlined"
            noOfRows={3}
            value={restaurantState?.description}
            onChange={(e) => {
              setRestaurantState((x) => ({
                ...x,
                description: { ...x.description, [selectedLanguage]: e.target.value },
              }));
            }}
          />

          {/* <Grid item xs={12} md={6} lg={6}> */}

          {/* </Grid> */}

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                hideDialog();
                dispatch(setSelectedRestaurant(null));
              }}
            >
              Cancel
            </Button>
            {selectedRestaurant ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateRestaurantData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateRestaurantData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedRestaurant(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
          {/* dispatch(setSelectedRestaurant(null)); */}
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateRestaurant;
