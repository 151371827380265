import { selectedCategorySelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { useGetCategoryQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { Config } from 'constant';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewCategory = ({ selectId }) => {

    const selectedCategory = useSelector(selectedCategorySelector);
    const [CategoryId, setCategoryId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingCategory, error: errorCategory } = useGetCategoryQuery(CategoryId);
    const category = res_get?.data?.category;
    const [selectedFile, setSelectedFile] = useState(category?.image);
    const selectedLanguage = useSelector(selectedLanguageSelector);
   
    useEffect(() => {
        category?.image && setSelectedFile(category?.image)
        setCategoryId(selectedCategory ? selectedCategory?.id : selectId)
    }, [])
    const categoryArray = [ { label: 'Name', data: category?.name[selectedLanguage] }, ]
    const { hideDialog } = useJumboDialog();
    return (
        <div>
            {
                errorCategory ? (
                    <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                        <div>{JSON.stringify(errorCategory)}</div>
                        <Button variant="contained" onClick={() => { hideDialog() }}>Close</Button>
                    </div>
                ) : isLoadingCategory ? <div>Loading Single Category.....</div>
                : category ? (
                    <DZGenericView obj={categoryArray} imagePath={Config.digitalOceanLinkCategorySmallImg} image={selectedFile} checkImage={true} closeBtn={true} /> 
                ) : <div>Bad Code</div>
            }
        </div>
    )
}

export default ViewCategory

