// ConfigRouteGuard.jsx
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Config } from '../../../constant';

const ConfigRouteGuard = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const hasAccess = localStorage.getItem(Config.showConfigurations) && 
                      localStorage.getItem(Config.superAdminApiTokenName);
    
    if (!hasAccess) {
      navigate('/login-super-admin');
    }
  }, [navigate]);

  return children;
};

export default ConfigRouteGuard;