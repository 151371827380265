const serverUrl = process.env.REACT_APP_SERVER_URL;
const endPointPath = process.env.REACT_APP_END_POINT_PATH;
const appName=process.env.REACT_APP_NAME;
const path=process.env.REACT_APP_PATH;
const tableOrderLink=process.env.REACT_APP_TABLE_ORDER;
const image_base_url=process.env.REACT_APP_IMAGE_LINK; //process.env.REACT_APP_IMAGE_BASE_URL;

export const Config = {

  endPointPath:endPointPath,
  projectName:appName,
  tableOrderLink:tableOrderLink,
  path:path,
  secretPass: "XkhZG4fW2t2W",
  serverUrl: serverUrl,
  serverApiUrl: serverUrl + 'api/',
  serverUrlImages: serverUrl + 'public/images/',
  serverUrlCategoryImages: serverUrl + 'images/categories/',
  serverUrlProductImages: serverUrl + 'public/images/products/',
  serverUrlUserImages: serverUrl + 'public/images/users/',
  adminApiTokenName: 'admin-login-token',
  superAdminApiTokenName: 'super-admin-login-token',
  roleName: 'role',
  showConfigurations: 'show-features',
  email: 'email',
  verificationCode: 'verifiactionCode',

  userApiTokenName: 'user-login-token',
  setAdminRestaurant: 'set-admin-restaurant',
  restaurant_id: 'restaurant-id',
  googleApiKey: 'AIzaSyD4BUDTEpTPIuhhJ2MyQ4AiQ0u3CyFlWOo',

  // Used in whole app
  currency_symbol: "DKK",
  currency: "Kr.",

  appName: appName,

  pushTokenName: 'device-push-token',

  // Here add langues and then add transaltion file for languages
  languages: [
    { shortName: 'en', icon: '', longName: 'English' },
    { shortName: 'da', icon: '', longName: 'Danish' }
  ],
  firstVisitDone: "firstVisitDone",

  defaultActivityImg: "../select-image.jpg",
  defaultImg: "select-image.jpg",
  defaultProductImageURI: 'https://www.thespruceeats.com/thmb/vJUFf6L4p8y9Cn_1pE9Z7Ua9uok=/3000x2001/filters:fill(auto,1)/indian-style-burger-1957599-hero-01-266103a4bb4e4ee7b5feb4da2d2e99da.jpg',

  digitalOceanLinkProductSmallImg:image_base_url+'products/small/',
  // category paths
  digitalOceanLinkCategorySmallImg: image_base_url+'categories/small/',
  digitalOceanLinkGallerySmallImg:  image_base_url+'galleries/small/',
  // Admin image paths
  digitalOceanLinkAdminSmallImg: image_base_url+'admin/small/',
  // User image paths
  digitalOceanLinkUserSmallImg: image_base_url+'user/small/',

  // Restaurant image paths
  digitalOceanLinkRestaurantSmallImg: image_base_url+'restaurant/small/',

  // Restaurant image paths for update images
  digitalOceanLinkRestaurantSmallImage: image_base_url+'restaurant_images/small/',
}
