import React, { useEffect, useState } from "react";
import routes from "./routes";
import {useJumboRoutes} from "@jumbo/hooks";

const AppRoutes = () => {
    const [appState, setAppState] = useState({ version: 0 });
    const appRoutes = useJumboRoutes(routes);
    
    useEffect(() => { // this code forcefully rerendering the App on local storage changes
        const handleStorageChange = () => setAppState(prev => ({ ...prev, version: prev.version + 1 }));
    
        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);
    
    return (
        <React.Fragment>
            {
                appRoutes
            }
        </React.Fragment>
    );
};
export default AppRoutes;


// const AppRoutes = () => {
//     const [appState, setAppState] = useState({ version: 0 });
//     const appRoutes = useJumboRoutes(routes);
//     const mountedRef = useRef(false);
//     const appRoutesRef = useRef(appRoutes);

//     useEffect(() => {
//         appRoutesRef.current = appRoutes;
//     }, [appRoutes]);

//     const handleStorageChange = useCallback((event) => {
//         if (!mountedRef.current) return;
        
//         console.log("Routes updated...", JSON.stringify(appRoutesRef.current));
//         setAppState(prev => ({ ...prev, version: prev.version + 1 }));
//     }, []);

//     const handleLocalUpdate = useCallback(() => {
//         if (!mountedRef.current) return;
//         setAppState(prev => ({ ...prev, version: prev.version + 1 }));
//     }, []);

//     useEffect(() => {
//         mountedRef.current = true;  // Mark component as mounted
        
//         window.addEventListener('storage', handleStorageChange);
//         window.addEventListener('localStorageUpdate', handleLocalUpdate);

//         return () => {
//             mountedRef.current = false;  // Mark component as unmounted
//             window.removeEventListener('storage', handleStorageChange);
//             window.removeEventListener('localStorageUpdate', handleLocalUpdate);
//         };
//     }, [handleStorageChange, handleLocalUpdate]);

//     return <React.Fragment>{appRoutes}</React.Fragment>;
// };

// export default AppRoutes;