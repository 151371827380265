import React, { useState } from "react";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import CategoryIcon from '@mui/icons-material/Category';
import AppsIcon from '@mui/icons-material/Apps';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import FormatListNumberedRtlOutlinedIcon from '@mui/icons-material/FormatListNumberedRtlOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { ROLE } from "app/rkt_query/SettingsReducer";
import useCheckRoles from "@jumbo/dz-components/custom-hooks/useCheckRoles";
import { decryptStoredData } from "app/pages/storetoken/StoreTokenCheck";
import { Config } from "constant";
import { selectedUserLoginSelector } from "app/rkt_query/SelectedStuffReducer";
import { useSelector } from "react-redux";
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import TableBarOutlinedIcon from '@mui/icons-material/TableBarOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import CollectionsIcon from '@mui/icons-material/Collections';

export const checkRole = (status) => {
    switch (status) {
        case ROLE.Admin:
            return ROLE.Admin;
        case ROLE.Manager:
            return ROLE.Manager;
        case ROLE.Owner:
            return ROLE.Owner;
        default:
            return ROLE.Manager;

    }
};

const role = decryptStoredData(Config.roleName)
const showConfig = decryptStoredData(Config.showConfigurations);
const superAdmin = decryptStoredData(Config.superAdminApiTokenName);

export const adminOwnerCondition = () => {
    //   const role_name=GetFromRedux();
    return (checkRole(role) === ROLE.Admin || checkRole(role) === ROLE.Owner);
}
export const adminOwnerManagerCondition = () => {
    //   const role_name=GetFromRedux();
    return (checkRole(role) === ROLE.Admin || checkRole(role) === ROLE.Owner || checkRole(role) === ROLE.Manager);
}
export const adminCondition = () => {
    return checkRole(role) === ROLE.Admin ? true : false;
}
export const showConfigurations = () => showConfig && superAdmin;

const menus =showConfigurations()? [{
            label: 'Configurations',
            type: "section",
            children: [{
                uri: "/restaurant-feature",
                label: 'Restaurant Feature',
                type: "nav-item",
                visible: showConfigurations(),
                icon: <FeaturedPlayListOutlinedIcon sx={{ fontSize: 20 }} />
            },] 
        },
    ]
    : [    {

        label: 'home',
        type: "section",
        children: [
            {
                uri: "/",
                label: 'Dashboard',
                type: "nav-item",
                visible: true,
                icon: <DashboardIcon sx={{ fontSize: 20 }} />,
            },
            {

                uri: "/order-list",
                label: 'Orders List',
                type: "nav-item",
                visible: true,
                icon: <FormatListNumberedRtlOutlinedIcon sx={{ fontSize: 20 }} />

            },
            {

                uri: "/order-detail",
                label: 'Orders',
                type: "nav-item",
                visible: true,
                icon: <FormatListNumberedRtlOutlinedIcon sx={{ fontSize: 20 }} />

            },
            {

                uri: "/order-table",
                label: 'Table Orders',
                type: "nav-item",
                visible: true,
                icon: <FormatListNumberedRtlOutlinedIcon sx={{ fontSize: 20 }} />

            },

        ]

    },

    adminOwnerManagerCondition() && {
        label: 'modules',
        type: "section",
        children: [
            {
                uri: "/categories",
                label: 'Category',
                type: "nav-item",
                visible: true,
                icon: <CategoryIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/products",
                label: 'Product',
                type: "nav-item",
                visible: true,

                icon: <ProductionQuantityLimitsIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/accessories",
                label: 'Accessory',
                type: "nav-item",
                visible: true,

                icon: <AppsIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/size-groups",
                label: 'Size Group',
                type: "nav-item",
                visible: true,

                icon: <LocalLibraryOutlinedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/gallery",
                label: 'Gallery',
                type: "nav-item",
                visible: true,

                icon: <CollectionsIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/media-room",
                label: 'Media Room',
                type: "nav-item",
                visible: true,

                icon: <CollectionsIcon sx={{ fontSize: 20 }} />
            },
        ]

    },



    adminOwnerManagerCondition() && {
        label: 'Restaurant',
        type: "section",
        children: [
            {
                label: 'Restaurant',
                type: "collapsible",
                icon: <ImportContactsOutlinedIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/restaurant-info",
                        label: 'Restaurant Info',
                        type: "nav-item",
                        visible: adminOwnerManagerCondition(),
                        icon: <ImportContactsOutlinedIcon sx={{ fontSize: 20 }} />
                    },
                    {
                        uri: "/restaurant-time",
                        label: 'Restaurant Timing',
                        type: "nav-item",
                        visible: adminOwnerManagerCondition(),
                        icon: <AccessTimeOutlinedIcon sx={{ fontSize: 20 }} />
                    },
                    {
                        uri: "/restaurant-table",
                        label: 'Restaurant Table',
                        type: "nav-item",
                        visible: adminOwnerManagerCondition(),
                        icon: <TableBarOutlinedIcon sx={{ fontSize: 20 }} />
                    },
                    // {
                    //     uri: "/restaurant-feature",
                    //     label: 'Restaurant Feature',
                    //     type: "nav-item",
                    //     visible: adminOwnerManagerCondition(),
                    //     icon: <FeaturedPlayListOutlinedIcon sx={{ fontSize: 20 }} />
                    // },
                    {
                        uri: "/restaurant-post-code",
                        label: 'Restaurant PostCode',
                        type: "nav-item",
                        visible: adminOwnerManagerCondition(),
                        icon: <FeaturedPlayListOutlinedIcon sx={{ fontSize: 20 }} />
                    },
                    {
                        uri: "/restaurant-owner-card",
                        label: 'Add Your Card',
                        type: "nav-item",
                        visible: adminOwnerManagerCondition(),
                        icon: <FeaturedPlayListOutlinedIcon sx={{ fontSize: 20 }} />
                    },

                ]
            },


        ]
    },




    adminOwnerCondition() && {

        label: 'authPages',
        type: "section",
        children: [

            {
                label: 'Auth',
                type: "collapsible",
                icon: <GroupOutlinedIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/users",
                        label: 'Customer',
                        type: "nav-item",
                        visible: adminOwnerCondition(),
                        icon: <GroupOutlinedIcon sx={{ fontSize: 20 }} />
                    },
                    {
                        uri: "/posc",
                        label: 'Ssk Users',
                        type: "nav-item",
                        visible: adminOwnerCondition(),
                        icon: <SupportAgentOutlinedIcon sx={{ fontSize: 20 }} />,
                        is_super: true
                    },
                    {
                        uri: "/admins",
                        label: 'Admin',
                        type: "nav-item",
                        visible: adminOwnerCondition(),
                        icon: <SupervisorAccountOutlinedIcon sx={{ fontSize: 20 }} />,
                        is_super: true
                    },

                ]
            },

            {
                uri: "/contacts",
                label: 'Contacts',
                type: "nav-item",
                visible: adminOwnerCondition(),
                icon: <ContactPhoneOutlinedIcon sx={{ fontSize: 20 }} />,
                is_super: true
            },

        ]

    },


];



export default menus;



