import { useEffect, useMemo } from "react";
import { decryptStoredData } from "app/pages/storetoken/StoreTokenCheck";
import { useGetAdminsAllRestaurantsQuery, useGetMeQuery } from "app/rkt_query/storeApis";
import { Config } from "constant";

export const useRestaurantInfo = () => {
  const storedToken = decryptStoredData(Config.adminApiTokenName);
  const { data: res_get_all, isLoading, isSuccess, error, refetch } = useGetAdminsAllRestaurantsQuery();
  
  const restaurantId = localStorage.getItem(Config.restaurant_id);
  const restaurants = res_get_all?.data?.restaurants || [];
  const restaurantInfo = restaurants.find((r) => r.id == restaurantId) || restaurants[0];

  useEffect(() => { refetch(); }, [storedToken]);

  return {
    restaurants,
    restaurant_id: restaurantInfo?.id,
    restaurant_name: restaurantInfo?.name,
    getRestaurantId: () => restaurantInfo?.id,
    getRestaurantName: () => restaurantInfo?.name,
    isLoading,
    isSuccess,
    error,
  };
};
