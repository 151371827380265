import { TextField, Fab, FormControlLabel, Checkbox } from "@mui/material";
import { selectedLanguageSelector, } from "app/rkt_query/SettingsReducer";
import { useGetCurrentRestaurantQuery, useUpdateRestaurantMutation, } from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DZTextField from "./../../../@jumbo/dz-components/textboxes/DZTextField";
import Snackbar from "@mui/material/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import Grid from "@mui/material/Grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ListItem, ListItemText, } from "@mui/material";
import DawaAutoAddress from "@jumbo/dz-components/dawa-address/DawaAutoAddress";
import { DzNoBackgroundTranslatable } from "@jumbo/dz-components/labels/DZLabel";
import DZDefaultImg from "app/DZcomponents/DZDefaultImg";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const UpdateViewRestaurantInfo = () => {
  const [value, setValue] = React.useState("1");
  const [open, setOpen] = React.useState(false);
  const { data: res_get } = useGetCurrentRestaurantQuery();
  const restaurant = res_get?.data?.restaurant;
  const [updateRestaurant, responseUpdate] = useUpdateRestaurantMutation();
  const [restaurantState, setRestaurantState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [error, setError] = useState([]);
  const [count, setCount] = useState(true);
  const [imagePreview, setImagePreview] = useState("");
  const [selectedFile, setSelectedFile] = useState( restaurant?.image );
  const [loading, setLoading] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();
  const handleChange = (event, newValue) => { setValue(newValue); };
  const countZero = () => { setCount(true); };
  if (responseUpdate?.error?.data?.errors && count) {
    setCount(false);
    setError(responseUpdate?.error?.data?.errors);
  }
  useEffect(() => {
    restaurant?.image && setSelectedFile(restaurant?.image);
    setRestaurantState({ ...restaurant, ...restaurant?.restaurant_link,});
  }, [res_get]);

  const addUpdateRestaurantData = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("id", restaurant?.id);
    formData.append("name", restaurantState.name);
    formData.append("pickup_time", restaurantState.pickup_time);
    formData.append("delivery_time", restaurantState.delivery_time);
    formData.append("delivery_min_price", restaurantState.delivery_min_price??0);
    formData.append("web_discount", restaurantState.web_discount);
    formData.append("app_discount", restaurantState.app_discount);
    formData.append("posc_discount", restaurantState.posc_discount);
    formData.append("delivery_distance", restaurantState.delivery_distance??0);
    formData.append("delivery_per_km_amount", restaurantState.delivery_per_km_amount);
    formData.append("fixed_amount_of_radius", restaurantState.fixed_amount_of_radius);
    formData.append("phone", restaurantState.phone);
    formData.append("health_care_link", restaurantState.health_care_link);
    formData.append("email", restaurantState.email);
    formData.append("lat", restaurantState.lat);
    formData.append("long", restaurantState.long);
    formData.append("address", restaurantState.address);
    formData.append("stars", restaurantState.stars??0);
    formData.append("facebook", restaurantState.facebook??"");
    formData.append("twitter", restaurantState.twitter??"");
    formData.append("linkedin", restaurantState.linkedin??"");
    formData.append("instagram", restaurantState.instagram??"");
    formData.append("tiktok", restaurantState.tiktok??"");
    formData.append("youtube", restaurantState.youtube??"");
    formData.append("is_delivery_per_km", restaurantState.is_delivery_per_km ? 1 : 0);
    formData.append("description", JSON.stringify(restaurantState.description));
    
    await updateRestaurant(formData)
      .unwrap()
      .then((payload) => {
        setLoading(false);

        if(payload?.success) fnShowSnackBar( payload?.message || "Done successfully", )
        else fnShowSnackBar("Something went wrong", true);
        
        setOpen(true);
      }).catch((error) => { fnShowSnackBar("Something went wrong", true); });
  };
  
  const filehandle = (e) => {
    console.log("file handle image", e.target.files[0]);
    if (e.target.files.length !== 0) {
      setSelectedFile(e.target.files[0]);
      console.log("Checking", selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => { setImagePreview(reader.result); };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleToClose = (event, reason) => {
    if ("clickaway" == reason) return;
    setOpen(false);
  };

  return (
    <>
      <div>
        {showBackDrop && <SimpleBackdrop responseUpdate={responseUpdate} />}
        <Snackbar open={open} onClose={handleToClose} sx={{ mt: 8 }} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} >
          <Alert onClose={handleToClose} severity="success" sx={{ width: "100%" }} > {responseUpdate?.data?.message} </Alert>
        </Snackbar>

        <JumboDemoCard title={"Restaurant Info"} wrapperSx={{ p: 0, backgroundColor: "background.paper", "&:last-child": { pb: 0 }, }} >
          <div style={{ marginTop: 25, display: "flex", flexDirection: "column", gap: 25, marginBottom: 60, }} >
          <DZDefaultImg src={imagePreview} selectedFile={selectedFile}/>
          
            {/* {imagePreview ? (
              <img src={imagePreview} alt="" style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center", }} />
            ) : (
              <DZImageCircle restaurant="restaurant" image={selectedFile} link={Config.digitalOceanLinkRestaurantSmallImg} style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center", }} />
            )} */}

            <Box sx={{ display: "flex", paddingBottom: "0px", justifyContent: "center", paddingTop: "0px", }} >
              <label htmlFor="upload-photo">
                <input onChange={filehandle} style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" />
                <Fab color="primary" size="small" component="span" aria-label="add" variant="extended" > Upload photo </Fab>
              </label>
            </Box>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{marginLeft: '42px'}} >
                    <Tab label="Restaurant Info" value="1" />
                    <Tab label="Restaurant Social Links" value="2" />
                  </TabList>

                  <Box sx={{ flex: 1 }}>
                    <TabPanel value="1" sx={{ marginLeft: 0 }}>
                      <Grid container spacing={3.75}>
                        <DZTextValidation col={6} sx={12} sx3mb={0.5} sx3mt={1} sx3ml={2} error={error?.name} label="Title" variant="outlined"
                          value={restaurantState?.name}
                          onChange={(e) => { setRestaurantState((x) => ({ ...x, name: e.target.value, })); }}
                        />
                        <DZTextValidation sx={12} col={6} sx3mb={0.5} sx3mt={1} sx3mr={2} error={error?.email} label="Email" variant="outlined"
                          value={restaurantState?.email}
                          onChange={(e) => { setRestaurantState((x) => ({ ...x, email: e.target.value, })); }}
                        />
                        <DZTextValidation sx={12} col={6} sx3ml={2} fullWidth label="Phone" type="number" inputProps={{ min: 1 }} variant="outlined" noOfRows={1}
                          value={restaurantState?.phone}
                          onChange={(e) => { setRestaurantState((x) => ({ ...x, phone: e.target.value, })); }}
                        />
                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mr: 2 }}>
                            <TextField fullWidth label="Stars" type="number" variant="outlined" noOfRows={1}
                              value={restaurantState?.stars} InputLabelProps={{ shrink: true }}
                              onChange={(e) => { setRestaurantState((x) => ({ ...x, stars: e.target.value, })); }}
                            />
                          </Div>
                        </Grid>

                        <hr style={{ width: '100%', border: '1px solid #7352C740', marginTop: '30px' }} />

                        <Grid item xs={12} md={12} lg={12}>
                          <Div sx={{ ml: 2 }}>
                            <DzNoBackgroundTranslatable title={"Discount"} />
                          </Div>
                        </Grid>

                        <Grid item xs={12} md={4} lg={4}>
                          <Div sx={{ ml: 2 }}>
                            <TextField fullWidth label="Web Discount (%)" type="number" error={error?.web_discount} variant="outlined" noOfRows={1}
                              value={restaurantState?.web_discount} InputLabelProps={{ shrink: true }}
                              onChange={(e) => { setRestaurantState((x) => ({ ...x, web_discount: e.target.value, })); }}
                            />
                          </Div>
                        </Grid>

                        <Grid item xs={12} md={4} lg={4}>
                          <Div >
                            <TextField fullWidth label="App Discount (%)" type="number" error={error?.app_discount} variant="outlined" noOfRows={1}
                              value={restaurantState?.app_discount} InputLabelProps={{ shrink: true }}
                              onChange={(e) => { setRestaurantState((x) => ({ ...x, app_discount: e.target.value, })); }}
                            />
                          </Div>
                        </Grid>

                        <Grid item xs={12} md={4} lg={4}>
                          <Div >
                            <TextField fullWidth label="POSC Discount (%)" type="number" error={error?.posc_discount} variant="outlined" noOfRows={1}
                              value={restaurantState?.posc_discount} InputLabelProps={{ shrink: true }}
                              onChange={(e) => { setRestaurantState((x) => ({ ...x, posc_discount: e.target.value, })); }}
                            />
                          </Div>
                        </Grid>

                        <hr style={{ width: '100%', border: '1px solid #7352C740', marginTop: '30px' }} />


                        <FormControlLabel control={<Checkbox checked={restaurantState?.is_delivery_per_km == 0 ? false : true}
                            onChange={(e) => { setRestaurantState((x) => ({ ...x, is_delivery_per_km: e.target.checked, })); }} />
                          } label="Is Delivery Per Km" sx={{ ml: "32px" }} // Adjust the margin value as needed
                        />

                        <Grid item xs={12} md={12} lg={12}>
                          <Div sx={{ ml: 2 }}>
                            <DzNoBackgroundTranslatable title={"Delivery"} />
                          </Div>
                        </Grid>

                        {/* <DZTextField sx={12} col={4} sx3ml={2} label="Delivery Time" error={error?.delivery_time} variant="outlined" noOfRows={1}
                          value={restaurantState?.delivery_time}
                          onChange={(e) => { setRestaurantState((x) => ({ ...x, delivery_time: e.target.value, })); }}
                        /> */}

                        {/* <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mr: 2 }}>
                            <TextField fullWidth label="Delivery Min Price" type="number" error={error?.delivery_min_price} variant="outlined" noOfRows={1}
                              value={restaurantState?.delivery_min_price} InputLabelProps={{ shrink: true }}
                              onChange={(e) => { setRestaurantState((x) => ({ ...x, delivery_min_price: e.target.value, })); }}
                            />
                          </Div>
                        </Grid> */}

                        <Grid item xs={12} md={4} lg={4}>
                          <Div sx={{ml: 2}}>
                            <TextField fullWidth label="Delivery Time" type="number" variant="outlined" noOfRows={1}
                              value={restaurantState?.delivery_time} InputLabelProps={{ shrink: true }}
                              onChange={(e) => { setRestaurantState((x) => ({ ...x, delivery_time: e.target.value, })); }}
                            />
                          </Div>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                          <TextField fullWidth label="Radius Distance in (km)" type="number" variant="outlined" noOfRows={1}
                            value={restaurantState?.delivery_distance} InputLabelProps={{ shrink: true }}
                            onChange={(e) => { setRestaurantState((x) => ({ ...x, delivery_distance: e.target.value, })); }}
                          />
                        </Grid>

                        <Grid item xs={12} md={4} lg={4}>
                          <TextField fullWidth label="Per Km Radius Amount" type="number" variant="outlined" noOfRows={1}
                            value={restaurantState?.delivery_per_km_amount} InputLabelProps={{ shrink: true }}
                            onChange={(e) => { setRestaurantState((x) => ({ ...x, delivery_per_km_amount: e.target.value, })); }}
                          />
                        </Grid>

                        <Grid item xs={12} md={4} lg={4}>
                          <Div sx={{ ml: 2 }}>
                            <TextField fullWidth label="Radius Fixed Amount" type="number" variant="outlined" noOfRows={1}
                              value={restaurantState?.fixed_amount_of_radius}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => { setRestaurantState((x) => ({ ...x, fixed_amount_of_radius: e.target.value, })); }}
                            />
                          </Div>
                        </Grid>
                        <hr style={{ width: '100%', border: '1px solid #7352C740', marginTop: '30px' }} />
                        <Grid item xs={12} md={6} lg={6}>
                          <DawaAutoAddress value={restaurantState?.address}
                            setAddress={(address) => setRestaurantState((prevState) => ({ ...prevState, address: address, })) }
                            setLat={(lat) => setRestaurantState((prevState) => ({ ...prevState, lat: lat, })) }
                            setLong={(long) => setRestaurantState((prevState) => ({ ...prevState, long: long, })) }
                          />
                        </Grid>

                        <DZTextField sx={12} sx3ml={2} col={6} sx3mt={1} error={error?.health_care_link} variant="outlined" noOfRows={1}
                          value={restaurantState?.health_care_link} label="Danish Veterinary and Food Administration Link"
                          onChange={(e) => setRestaurantState((x) => ({ ...x, health_care_link: e.target.value }))}
                        />

                        <DZTextField sx={12} col={12} sx3ml={2} sx3mr={2} label="Description" variant="outlined" noOfRows={3} maxRows={5}
                          value={restaurantState?.description}
                          onChange={(e) => { setRestaurantState((x) => ({ ...x, description: { ...x.description, [selectedLanguage]: e.target.value, }, })); }}
                        />

                      </Grid>

                    </TabPanel>

                    <TabPanel value="2" sx={{ marginLeft: 0 }}>
                      <Grid container spacing={3.75}>
                        {/* <Grid item xs={12} md={8} lg={8}> <DzNoBackgroundTranslatable title={"Learning Journey"} children={res_get1?.data?.learning_journey?.name} /> </Grid> */}
                        {/* social link */}
                        <DZTextValidation col={6} sx={12} sx3mb={0.5} sx3mt={1} sx3ml={2} error={error?.facebook} label="Facebook" variant="outlined"
                          value={restaurantState?.facebook?? ""}
                          onChange={(e) => { setRestaurantState((x) => ({ ...x, facebook: e.target.value, })); }}
                        />
                        <DZTextValidation sx={12} col={6} sx3mb={0.5} sx3mt={1} sx3mr={2} error={error?.instagram} label="Instagram" variant="outlined"
                          value={restaurantState?.instagram?? ""}
                          onChange={(e) => { setRestaurantState((x) => ({ ...x, instagram: e.target.value, })); }}
                        />

                        <DZTextValidation sx={12} col={6} sx3ml={2} fullWidth label="Twitter" type="number" inputProps={{ min: 1 }} variant="outlined" noOfRows={1}
                          value={restaurantState?.twitter?? ""}
                          onChange={(e) => { setRestaurantState((x) => ({ ...x, twitter: e.target.value, })); }}
                        />

                        <DZTextValidation sx={12} col={6} sx3mr={2} error={error?.LinkedIn} label="LinkedIn" variant="outlined" noOfRows={1}
                          value={restaurantState?.linkedin?? ""}
                          onChange={(e) => { setRestaurantState((x) => ({ ...x, linkedin: e.target.value, })); }}
                        />

                        <DZTextField sx={12} col={6} sx3ml={2} label="Youtube" error={error?.youtube} variant="outlined" noOfRows={1}
                          value={restaurantState?.youtube?? ""}
                          onChange={(e) => { setRestaurantState((x) => ({ ...x, youtube: e.target.value, })); }}
                        />
                        <DZTextField sx={12} col={6} sx3mr={2} label="TikTok" error={error?.tiktok} variant="outlined" noOfRows={1}
                          value={restaurantState?.tiktok?? ""}
                          onChange={(e) => { setRestaurantState((x) => ({ ...x, tiktok: e.target.value, })); }}
                        />
                      </Grid>
                    </TabPanel>
                  </Box>
                </Box>
              </TabContext>
            </Box>

            <div style={{ marginLeft: '14px' }}>
              <ListItem alignItems="flex-start" sx={{ p: (theme) => theme.spacing(0.5, 3) }} >
                <ListItemText
                  secondary={
                    <LoadingButton loading={loading} type="submit" variant="contained" size="large" sx={{ mb: 2 }}
                      onClick={() => { addUpdateRestaurantData(); setShowBackDrop(true); countZero(); }}
                    >Update</LoadingButton>
                  }
                />
              </ListItem>
            </div>
          </div>
        </JumboDemoCard>
      </div>
    </>
  );
};

export default UpdateViewRestaurantInfo;
