import React, { useEffect } from "react";
import { CircularProgress, ListItemText, ThemeProvider } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import { useJumboTheme } from "@jumbo/hooks";
import { Config } from "./../../../../constant/index";
import { encryptStoredData } from "app/pages/storetoken/StoreTokenCheck";
import { useRestaurantInfo } from "app/hooks/useRestaurantInfo";

const RestaurantDropdown = () => {
  const { theme } = useJumboTheme();
  const { restaurants, getRestaurantId, getRestaurantName, isLoading, isSuccess } = useRestaurantInfo();

  const handleSelectRestaurant = (rest) => {
    localStorage.setItem(Config.restaurant_id, rest?.id);
    window.location.reload();
  };

  if (isLoading) {
    return (
      <ListItemButton disabled>
        <CircularProgress size={20} />
        <ListItemText primary="Loading restaurants..." />
      </ListItemButton>
    );
  }
  
  return (
    (!isLoading && restaurants.length == 0) ?
      <ListItemButton 
        style={{ backgroundColor: "rgba(244, 67, 54, 0.1)", color: "#D32F2F", borderColor: "rgba(211, 47, 47, 0.2)" }}
      >
        <ListItemText style={{ color: "#D32F2F" }} primary={"Please, Create a Restaurant"} />
      </ListItemButton>
    :
      <ThemeProvider theme={theme}>
        {restaurants?.length > 0 && (
          <JumboDdPopover
            triggerButton={
              <ListItemButton style={{ backgroundColor: "rgba(115, 82, 199, 0.15)" }}>
                <ListItemText style={{ color: '#7352C7' }} primary={getRestaurantName()} />
              </ListItemButton>
            }
          >
            <nav>
              <List disablePadding sx={{ pb: 1, width: '230px' }}>
                {restaurants?.map(rest => (
                  <ListItemButton key={rest.id} onClick={() => handleSelectRestaurant(rest)}
                    style={getRestaurantId() == rest.id ? { backgroundColor: "rgba(115, 82, 199, 0.15)" } : null}
                  >
                    <ListItemText primary={rest.name} style={getRestaurantId() == rest.id ? { color: '#7352C7' } : null} />
                  </ListItemButton>
                ))}
              </List>
            </nav>
          </JumboDdPopover>
        )}
      </ThemeProvider>
  );
};

export default RestaurantDropdown;