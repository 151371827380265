import { FormControlLabel, Switch } from '@mui/material'
import React from 'react'

const DZSwitch = ({ checked=false, label, onChange }) => {
  return (
    <FormControlLabel
      control={<Switch checked={checked?true:false} onChange={onChange} />}
      label={label}
    />
  )
}

export default DZSwitch