import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
// import { authUser } from "./fake-db";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import { useJumboTheme } from "@jumbo/hooks";
import PP2Service from "./../../../services/index";
import { Config } from "./../../../../constant/index";
import { LoginSnackbar } from "@jumbo/dz-components/snackbar/Snackbar";
import { Link } from "react-router-dom";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { encryptStoredData, fnRemoveAuthToken } from "app/pages/storetoken/StoreTokenCheck";
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import { usePathname } from "app/hooks/usePathname";
const AuthUserDropdown = () => {
  const path_name = usePathname();
  const { fnShowSnackBar } = useSnackBarManager();
  const navigate = useNavigate();
  const [authUser, setUser] = useState(null);

  const { theme } = useJumboTheme();
  useEffect(() => {
    userProfile();
  }, []);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const userProfile = async () => {
    let response = await PP2Service.fetch("admin/me");

    if (response.success) {
      setUser(response.data);
      
      // setName(user?.name)
      // fnShowSnackBar(response.message);
    } else {
      if( path_name.includes("restaurant-list-for-super-admin")) return; // avoid navigate to admin page, in case of super admin and restaurant login list page
      // localStorage.removeItem(Config.adminApiTokenName);
      // localStorage.removeItem(Config.setAdminRestaurant);

      if(path_name.includes("login-super-admin")) return; // avoid navigate to admin page, in case of super admin and restaurant login list page
      
      navigate("/");
      // fnShowSnackBar(response.message, true);
      // fnOnLogout();
    }
  };

  const fnOnLogout = async () => {
    try {
      PP2Service.fetch("admin/logout").then((response) => {
        if (response.status === 200) {
        
        }
      });
    } catch (error) { }
    fnRemoveAuthToken();
    navigate("/");
  };
  const fnSetRestaurant = async (id) => {
  
   
    encryptStoredData(Config.setAdminRestaurant, id);
     
window.location.reload();
     
 
  
  };

  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={
          <Avatar
            src={
              authUser?.image
                ? Config.digitalOceanLinkAdminSmallImg + authUser?.image
                : authUser?.name
            }
            sizes={"small"}
            sx={{ boxShadow: 25, cursor: "pointer" }}
            alt={authUser?.name}
          />
        }
      >
        <Div
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            p: (theme) => theme.spacing(2.5),
          }}
        >
          <Avatar
            src={
              authUser?.image
                ? Config.digitalOceanLinkAdminSmallImg + authUser?.image
                : authUser?.name
            }
            alt={authUser?.name}
            sx={{ width: 60, height: 60, mb: 2 }}
          />
          <Typography variant={"h5"}>{authUser?.name}</Typography>
          <Typography variant={"body1"} color="text.secondary">
            {authUser?.email}
          </Typography>
        </Div>
        <Divider />
        <nav>
          <List disablePadding sx={{ pb: 1 }}>
            {/* <ListItemButton>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <PersonOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary="Profile" sx={{ my: 0 }} />
                        </ListItemButton> */}
                     

            <div>
                
      {/* <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Restaurant 
      </Button> */}
      {/* <ListItemButton onClick={handleClick}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <FeaturedPlayListOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Select Restaurant" sx={{ my: 0 }} />
            </ListItemButton> */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        
       {authUser?.restaurants?.map((restaurant) =>{

         return( <MenuItem key={restaurant?.id} onClick={()=>fnSetRestaurant(restaurant?.id)}>{restaurant?.name}</MenuItem>)
       })
    }
      </Menu>
    </div>
              {/* <Accordion>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>Select Restaurant </Typography>
                </AccordionSummary>
                
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <PersonOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary="Profile" sx={{ my: 0 }} />
                </ListItemButton>
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <PersonOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary="Profile" sx={{ my: 0 }} />
                </ListItemButton>
               
              </Accordion> */}
         
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <EditOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Edit Profile"
                onClick={() => navigate("/profile")}
                sx={{ my: 0 }}
              />
            </ListItemButton>

            {/* <ListItemButton>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <RepeatOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText onClick={() => navigate("/samples/content-layout")} primary="Switch User"
                                sx={{ my: 0 }} />
                        </ListItemButton> */}
            <ListItemButton onClick={fnOnLogout}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ my: 0 }} />
            </ListItemButton>
          </List>
        </nav>
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default AuthUserDropdown;
