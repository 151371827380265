import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React from 'react'
import { BLUE_PRINTS, useGetAllSuperAdminRestaurantsQuery, useRestaurantLoginForSuperAdminMutation,  } from 'app/rkt_query/storeApis';
import { useDispatch } from 'react-redux';
import DataTable from '../../../../@jumbo/dz-components/data-table/DataTable';
import { setSelectedUserLogin } from 'app/rkt_query/SelectedStuffReducer';
import { useNavigate } from 'react-router-dom';
import { Button, } from '@mui/material';
import { useJumboApp } from '@jumbo/hooks';
import { encryptStoredData } from '../../storetoken/StoreTokenCheck';
import { Config } from 'constant';
import { LAYOUT_NAMES } from 'app/layouts/layouts';

const RestaurantListForSuperAdmin = () => {
  const dispatch = useDispatch();
  const [userLogin, userLoginResponse] = useRestaurantLoginForSuperAdminMutation();
  const { setActiveLayout } = useJumboApp();
  const navigate = useNavigate();
  const { data: res_get_all, isLoading: isLoadingRestaurants, error: errorRestaurants } = useGetAllSuperAdminRestaurantsQuery();

  React.useEffect(() => { 
    localStorage.removeItem(Config.adminApiTokenName);
    localStorage.removeItem(Config.setAdminRestaurant);
    localStorage.removeItem(Config.showConfigurations);
    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE); 
  }, []);

  const handler = (restaurant, showConfigurations) => {
    const formData = new FormData();
    formData.append("restaurant_id", restaurant?.id);
    
    userLogin(formData).unwrap().then((payload) => {
      dispatch( setSelectedUserLogin("admin") );

      encryptStoredData(Config.setAdminRestaurant, restaurant?.id);
      localStorage.setItem(Config.restaurant_id, restaurant?.id);
      encryptStoredData(Config.adminApiTokenName, payload.data.access_token);
      encryptStoredData( Config.roleName, "admin" );
      if(showConfigurations){
        encryptStoredData(Config.showConfigurations, "true");
        setTimeout(() => {// setTimeout to ensure React re-renders routes
          if (localStorage.getItem(Config.showConfigurations)) navigate("/restaurant-feature");
          else navigate("/dashboard");
        }, 50); // Short delay to allow re-render
      }else navigate("/dashboard");
    })
    .catch((error) => { });
  };

  const bluePrint = BLUE_PRINTS.restaurantListForSuperAdmin;
  bluePrint.getCustomCols = (row) => {return ([
    { field: "owner-name", sortable: false, flex: 1, align: 'center', renderCell: (params) => ( <label>{params?.row?.admins?.[0]?.email}</label> ) },
    { field: "login-action", headerName: "", sortable: false, flex: 1, align: 'right',
      renderCell: (params) => {
        return (
          <>
            <Button variant="contained" color="primary" onClick={() => handler(params.row)}>Login</Button> 
            <Button variant="outlined" color="primary" onClick={() => handler(params.row, true)} sx={{ml: 1}}>Config</Button> 
          </>
        )
      }
    },
  ])}

  return (
    <JumboDemoCard title={"Login to any Restaurant as Admin or Configure restaurant Settings"} wrapperSx={{ p: 0, backgroundColor: "background.paper", "&:last-child": { pb: 0 }, }} sx={{ minHeight: '100vh' }} >
      <div style={{ height: "100%", width: "100%" }}>
        <DataTable hideHeader hideExportButton hideActions data={res_get_all?.data?.restaurants} bluePrint={bluePrint} showAddButton={true} 
          dtStyle={{ height: '90vh', padding:0, marginTop:"0" }}
        />
      </div>
    </JumboDemoCard>
  );
}


export default RestaurantListForSuperAdmin