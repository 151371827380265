import React, { useState, useEffect } from "react";
import { TextField, Grid, Snackbar, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { IMaskInput } from "react-imask";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import { useAddUpdateRestaurantOpeningHourMutation, useGetRestaurantOpeningHourQuery } from "app/rkt_query/storeApis";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DayTimeField = ({ day, fromValue, toValue, handleChange }) => {
  const dayPrefix = day.slice(0, 3).toLowerCase(); // Get the first three characters of the day name

  return (
    <>
      <Grid item xs={3} md={4} lg={4}>
        <Div sx={{ mb: 0.5, mt: 1, ml: 30 }}> <h3>{day}</h3> </Div>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <TextField label="From" name={`${dayPrefix}_from`} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: IMaskInput, inputProps: { mask: "00:00" }, }}
          value={fromValue || ""} onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <TextField label="To" name={`${dayPrefix}_to`} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: IMaskInput, inputProps: { mask: "00:00" }, }}
          value={toValue || ""} onChange={handleChange}
        />
      </Grid>
    </>
  );
};

const UpdateViewRestaurantTime = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(true);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [error, setError] = useState([]);
  const { data: res_get } = useGetRestaurantOpeningHourQuery();
  const [updateRestaurantOpeningHour, responseUpdate] = useAddUpdateRestaurantOpeningHourMutation();
  const [openingHours, setOpeningHours] = useState({
    sun_from: "", sun_to: "", mon_from: "", mon_to: "", tue_from: "", tue_to: "", wed_from: "", wed_to: "",
    thu_from: "", thu_to: "", fri_from: "", fri_to: "", sat_from: "", sat_to: "",
  });

  const countZero = () => { setCount(true); };

  if (responseUpdate?.error?.data?.errors && count) {
    setCount(false);
    setError(responseUpdate?.error?.data?.errors);
  }

  const handleChange = (e) => setOpeningHours({ ...openingHours, [e.target.name]: e.target.value });

  useEffect(() => { if (res_get?.data?.openingHours) setOpeningHours(res_get.data.openingHours); }, [res_get]);

  const handleToClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("openingHours", openingHours);
    updateRestaurantOpeningHour(openingHours)
      .unwrap()
      .then((payload) => {
        setLoading(false);
        setOpen(true);
      }).catch(() => setLoading(false))
      .finally(() => {
        setShowBackDrop(false);
        countZero();
      });
  };

  return (
    <div>
      {showBackDrop && <SimpleBackdrop responseUpdate={responseUpdate} />}
      <Snackbar sx={{ mt: 8 }} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={handleToClose} >
        <Alert onClose={handleToClose} severity="success" sx={{ width: "100%" }} >
          {responseUpdate?.data?.message}
        </Alert>
      </Snackbar>
      <JumboDemoCard title={"Restaurant Timing"} wrapperSx={{ p: 0, backgroundColor: "background.paper", "&:last-child": { pb: 0 }, }} >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map((day) => (
              <DayTimeField key={day} day={day} handleChange={handleChange}
                fromValue={openingHours[`${day.slice(0, 3).toLowerCase()}_from`]}
                toValue={openingHours[`${day.slice(0, 3).toLowerCase()}_to`]}
              />
            ))}
          </Grid>

          <ListItem alignItems="flex-start" sx={{ p: (theme) => theme.spacing(0.5, 3) }}>
            <ListItemIcon sx={{ minWidth: 36, color: "text.secondary" }} />
            <ListItemText
              secondary={ <LoadingButton loading={loading} type="submit" variant="contained" size="large" sx={{ mb: 3 }}> Update </LoadingButton> }
            />
          </ListItem>
        </form>
      </JumboDemoCard>
    </div>
  );
};

export default UpdateViewRestaurantTime;
