import { Box, Button, TextField, Fab, Checkbox, FormControlLabel, Tab, List, } from "@mui/material";
import { selectedProductSelector, setSelectedProduct, } from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector, } from "app/rkt_query/SettingsReducer";
import { BLUE_PRINTS, useAddProductMutation, useGetProductQuery, useUpdateProductMutation, useDeleteProductSizePriceMutation, useGetAllCategoriesQuery, useGetAllProductsQuery, } from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { NoHideSnackbar, } from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation, { MultiDZTextValidation, } from "@jumbo/dz-components/textboxes/DZTextValidation";
import Grid from "@mui/material/Grid";
import { Config } from "constant";
import DZLabel from "@jumbo/dz-components/labels/DZLabel";
import { Card, CardContent, } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { dialogButtonsStyle } from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
import DZDefaultImg from "app/DZcomponents/DZDefaultImg";
import SnackAndBackDrop from "app/DZcomponents/SnackAndBackDrop";

// import { AddCircle } from "@material-ui/icons";

const validationRules = {
  title: { required: true, },
  category_id: { required: true, },
  // price: { //   required: true, // },
  stars: { required: true, },
  cooking_time: { required: true, },
};
const AddUpdateProduct = ({ onClose }) => {
  const { hideDialog } = useJumboDialog();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [value, setValue] = React.useState("product");
  const dispatch = useDispatch();

  const selectedProduct = useSelector(selectedProductSelector);

  const { data: res_get_all } = useGetAllCategoriesQuery();
  const { data: get_all_suggested_products } = useGetAllProductsQuery();
  const { data: res_get } = useGetProductQuery(selectedProduct?.id);

  const [updateProduct, responseUpdate] = useUpdateProductMutation();
  const [addProduct, responseAdd] = useAddProductMutation();
  const [deleteProductSize, responseDelete] = useDeleteProductSizePriceMutation();

  const bluePrint = BLUE_PRINTS.product;

  const [categories, setCategories] = useState(null);
  const [sizes, setSizes] = useState(null);
  const [show, setShow] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();
  const [productState, setProductState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [open, setOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [selectedFile, setSelectedFile] = useState( res_get?.data?.product?.image );
  const [inputFields, setInputFields] = useState([ { id: "", price: "", size_id: "", name: "" }, ]);

  // validation
  const [formErrors, setFormErrors] = useState({});
  const validateForm = () => {
    const errors = {};

    Object.keys(validationRules).forEach((field) => {
      const rules = validationRules[field];

      // Check if the field is multilingual or not
      const isMultilingual = typeof productState[field] === "object";

      if (isMultilingual) {
        const languageField = `${field}`;
        if (rules.required && !productState[field]?.en) { errors[languageField] = ` ${field} is required`; }
      } else {
        // Non-multilingual field
        if (rules.required && !productState[field]) { errors[field] = `${field} is required`; }
      }
    });
    // console.log(errors);
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  const productIds = res_get?.data?.product?.suggested_products?.map( (product) => Number(product?.suggested_product_id) );

  useEffect(() => {
    res_get?.data?.product?.image && setSelectedFile(res_get?.data?.product?.image);
    setProductState(
      selectedProduct
        ? { ...res_get?.data?.product, products_id: productIds ?? [], }
        : { ...bluePrint, category_id: res_get?.data?.product?.category_id ?? "", size_group_id: res_get?.data?.product?.size_group_id, }
    );

    setInputFields( res_get?.data?.product?.prices ?? [ { id: "", price: "", size_id: "", name: "" }, ] );
    setTimeout(() => {
      const categories = res_get_all?.data?.categories?.map((category) => BLUE_PRINTS.category.translate(category, selectedLanguage) );
      const size_groups = res_get_all?.data?.size_groups?.map( (size_groups) => size_groups );
      setCategories(categories);
      setSizes(size_groups);
    }, 1000);
  }, [res_get, res_get_all]);

  const addUpdateProductData = async (action) => {
    const isValid = validateForm();

    if (isValid) {
      const formData = new FormData();
      formData.append("image", selectedFile);
      formData.append("id", res_get?.data?.product?.id);
      formData.append("title", JSON.stringify(productState.title));
      formData.append("size_group_id", productState.size_group_id);
      formData.append("category_id", productState.category_id);
      formData.append("detail", JSON.stringify(productState.detail));
      formData.append( "product_suggestions_id", JSON.stringify(productState.products_id) );
      formData.append("price", productState.price);
      formData.append("deal", productState.deal ? 1 : 0);
      formData.append("stars", productState.stars);
      formData.append("cooking_time", productState.cooking_time);
      formData.append("state", productState.state);
      formData.append("prices", JSON.stringify(inputFields));
      // console.log("inputFields", inputFields);
      setOpen(true);
      // return false;
      try {
        let result = null;
        
        if(action === "add") result = await addProduct(formData);
        else result = await updateProduct(formData);

        if (result?.data?.success) { setShow(false); fnShowSnackBar(result?.data?.message); }
        else fnShowSnackBar(result?.error?.data?.message, true);
        
        setShow(true);
      } catch (error) {
        setShow(true);
        fnShowSnackBar(error, false);
      }
    }
  };

  const filehandle = (e) => {
    if (e.target.files.length !== 0) {
      setSelectedFile(e.target.files[0]);
      // setImagePreview(URL.createObjectURL(e.target.files[0]));
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleFormChange = (index, event) => {
    setInputFields( inputFields.map((price, i) => 
        i === index ? { ...price, [event.target.name]: event.target.value, } : price 
    ));
  };

  const groupSize = (sizeGroupId) => {
    const sizesGroup = res_get_all?.data?.sizes?.filter( (size) => size.size_group_id == sizeGroupId );
    const sizesForGroup = sizesGroup?.map((item) => { return { price: "", size_id: item.id, name: item.title, }; });
    setInputFields(sizesForGroup);
  };

  return (
    <div>
      <SnackAndBackDrop showBackDrop={showBackDrop} responseAdd={responseAdd} responseUpdate={responseUpdate} setOpen={setOpen} open={open} />
      {openSnackbar ? ( <NoHideSnackbar responseAdd={responseDelete?.isSuccess === true ? responseDelete : ""} /> ) : ( "" )}
      {productState ? (
        <div style={{ marginTop: 25, display: "flex", flexDirection: "column", gap: 25, marginBottom: 60, }} >
          <DZDefaultImg src={imagePreview} selectedFile={selectedFile} />
          {/* {imagePreview ? (
            <img src={imagePreview} alt={selectedFile} style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center", }} />
          ) : (
            <DZImageCircle product="product" image={selectedFile} link={Config.digitalOceanLinkProductSmallImg} style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center", }} />
          )} */}
          <Box sx={{ display: "flex", paddingBottom: "0px", justifyContent: "center", paddingTop: "0px", }} >
            <label htmlFor="upload-photo">
              <input style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" onChange={filehandle} />
              <Fab color="primary" size="small" component="span" aria-label="add" variant="extended" >
                Upload photo
              </Fab>
            </label>
          </Box>
          <TabContext value={value}>
            <TabList onChange={(event, newValue) => setValue(newValue)} sx={{ borderBottom: 1, borderColor: "divider" }} >
              <Tab label={"Product Detail"} value={"product"} sx={{ flex: "1 1 auto" }} />
              <Tab label={"Product Suggestion"} value={"product_suggestion"} sx={{ flex: "1 1 auto" }} />
            </TabList>
            <TabPanel value="product" sx={{ p: 0 }}>
              <List>
                <Grid container spacing={3.75}>
                  <FormControlLabel
                    control={
                      <Checkbox onChange={(e) => { setProductState((x) => ({ ...x, deal: e.target.checked, })); }} checked={productState?.deal != 0} />
                    }
                    label="Deal"
                    sx={{ ml: "16px" }} // Adjust the margin value as needed
                  />
                  <MultiDZTextValidation value={productState?.title} col={12} sx={12} error={formErrors?.title} label="Title" variant="outlined"
                    onChange={(e) => { setProductState((x) => ({ ...x, title: { ...x.title, [selectedLanguage]: e.target.value, }, })); }}
                  />

                  {categories && (
                    <Grid item xs={12} md={12} lg={12}>
                      <Div sx={{ mb: 0.5, mt: 1 }}>
                        <DZDropdown error={formErrors?.category_id} value={productState?.category_id} data={categories} placeholder="Select Category"
                          onChange={(e) => { setProductState((x) => ({ ...x, category_id: e.target.value, })); }}
                        />
                      </Div>
                    </Grid>
                  )}

                  <Grid item xs={12} md={6} lg={6}>
                    <Div sx={{ mb: 0.5, mt: 1 }}>
                      <TextField fullWidth label="Stars" type="number" InputLabelProps={{ shrink: true }} inputProps={{ min: 1 }} 
                        variant="outlined" noOfRows={1} error={!!formErrors.stars} helperText={formErrors.stars}
                        value={productState?.stars} onChange={(e) => { setProductState((x) => ({ ...x, stars: e.target.value, })); }}
                        {...(error.stars && { error: true, helperText: error.stars, })}
                      />
                    </Div>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <Div sx={{ mb: 0.5, mt: 1 }}>
                      <TextField InputLabelProps={{ shrink: true }} fullWidth label="Cooking Time" type="number" inputProps={{ min: 1 }} variant="outlined" noOfRows={1} error={!!formErrors.cooking_time} helperText={formErrors.cooking_time}
                        value={productState?.cooking_time}
                        onChange={(e) => { setProductState((x) => ({ ...x, cooking_time: e.target.value, })); }}
                        {...(error.cooking_time && { error: true, helperText: error.cooking_time, })}
                      />
                    </Div>
                  </Grid>
                </Grid>
                <DZTextField label="Description" variant="outlined" noOfRows={3}
                  value={productState?.detail}
                  onChange={(e) => { setProductState((x) => ({ ...x, detail: { ...x.detail, [selectedLanguage]: e.target.value, }, })); }}
                />
                <div style={{ width: "100%" }}>
                  <DZLabel title={"Product Size Price"} />
                  <Grid item xs={12} md={12} lg={12}>
                    <Div sx={{ mb: 0.5, mt: 1 }}>
                      {sizes && (
                        <DZDropdown error={error?.size_group_id} fieldTextName={"short_title"} placeholder="Select Size"
                          value={productState?.size_group_id}
                          data={sizes}
                          onChange={(e) => { setProductState((x) => ({ ...x, size_group_id: e.target.value, })); groupSize(e.target.value); }}
                        />
                      )}
                    </Div>
                  </Grid>
                </div>

                <form>
                  {/* return ( */}
                  <Card sx={{ overflow: "visible", mt: 4, border: 1 }}>
                    <CardContent>
                      {inputFields?.map((input, index) => {
                        return (
                          <div key={index}>
                            <Grid container spacing={3.75}>
                              <Grid item xs={12} md={6} lg={6}>
                                <Div sx={{ mb: 0.5, mt: 1 }}>
                                  <input value={input?.size_id} type="hidden" name="size_id"
                                    onChange={(event) => handleFormChange(index, event) }
                                  />
                                  <input value={input?.id} type="hidden" name="id"
                                    onChange={(event) => handleFormChange(index, event) }
                                  />
                                  <DZTextValidation label="Size" variant="outlined" noOfRows={1}
                                    value={ input?.size?.title?.[selectedLanguage] ?? input?.name?.[selectedLanguage] }
                                  />
                                </Div>
                              </Grid>
                              <Grid item xs={12} md={6} lg={6}>
                                <Div sx={{ mb: 0.5, mt: 1 }}>
                                  <TextField fullWidth label="Price" InputLabelProps={{ shrink: true }} type="number" name="price" variant="outlined" noOfRows={1}
                                    value={input?.price} onChange={(event) => handleFormChange(index, event) }
                                  />
                                </Div>
                              </Grid>
                            </Grid>
                          </div>
                        );
                      })}
                    </CardContent>
                  </Card>
                </form>
              </List>
              {/* </JumboScrollbar> */}
            </TabPanel>
            <TabPanel value="product_suggestion" sx={{ p: 0 }}>
              <JumboScrollbar autoHeight autoHeightMin={790} autoHide autoHideDuration={200} autoHideTimeout={500} >
                <List
                  disablePadding
                  sx={{ display: "flex", flexWrap: "wrap", gap: "20px", alignItems: "center", justifyContent: "center", margin: (theme) => theme.spacing(0, 1), }}
                >
                  {get_all_suggested_products?.data?.products?.map(
                    (product) => (
                      <div
                        className="suggested__card"
                        style={{
                          display: "flex", flexDirection: "column", gap: "10px", boxShadow: "0 4px 10px rgba(127, 17, 224, .1)",
                          width: "fit-content", padding: "10px", borderRadius: "10px",
                        }}
                      >
                        <div className="card__img" style={{ position: "relative" }} >
                          <img
                            src={ product?.image ? Config.digitalOceanLinkProductSmallImg + product?.image : Config.defaultImg }
                            alt=""
                            style={{ width: "240px", height: "140px", borderRadius: "10px", }}
                          />
                        </div>

                        <div
                          className="product__data"
                          style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", }}
                        >
                          <div className="checkbox">
                            <FormControlLabel
                              control={
                                <Checkbox checked={productState?.products_id?.includes( product?.id )}
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    setProductState((prevState) => ({ ...prevState,
                                      products_id: checked ? [ ...prevState?.products_id, product?.id, ]
                                        : prevState?.products_id.filter( (id) => id != product?.id ),
                                    }));
                                  }}
                                />
                              }
                            />
                          </div>
                          <span className="title"
                            style={{ fontSize: "18px", color: "rgba(127, 17, 224, 1)", }}
                          >
                            {product?.title?.[selectedLanguage]}
                          </span>
                        </div>
                      </div>
                    )
                  )}
                </List>
              </JumboScrollbar>
            </TabPanel>
          </TabContext>
          <div style={dialogButtonsStyle}>
            <Button variant="outlined" onClick={() => { hideDialog(); dispatch(setSelectedProduct(null)); }} >
              Cancel
            </Button>
            {res_get?.data?.product?.id ? (
              <Button variant="contained"
                onClick={() => {
                  addUpdateProductData();
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedProduct(null));
                }}
              >
                Update
              </Button>
            ) : (
              <Button variant="contained"
                onClick={() => {
                  addUpdateProductData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedProduct(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateProduct;
