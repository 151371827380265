import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, {  useState } from 'react'
import { useGetAllCategoriesQuery, BLUE_PRINTS, useDeleteCategoryMutation, } from 'app/rkt_query/storeApis';
import { useDispatch } from 'react-redux';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Category from './AddUpdateCategory';
import { setSelectedCategory } from 'app/rkt_query/SelectedStuffReducer';
import ViewCategory from './ViewCategory';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { SimpleButtons } from '@jumbo/dz-components/header-buttons/HeaderButtons';
import Ordering from '@jumbo/dz-components/drag-drop-ordering/Ordering';

const CategoryList = () => {
  const location = useLocation();
  console.log("In Category Relational Object", location?.state);

  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteCategory, responseDelete] = useDeleteCategoryMutation();
  const { data: res_get_all, } = useGetAllCategoriesQuery();
  const [showMode, setShowMode] = useState(false);
  const size="xl";

  const bluePrint = BLUE_PRINTS.category;
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedCategory(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedCategory(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedCategory(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedCategory(row)); }
  bluePrint.handleDelete = (id) => { deleteCategory(id); }
  
  return (
    <>
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
        {
          <SimpleButtons title={"Category Ordering"} 
            onClick={() =>
              showDialog({ title: 'Product List', size, content: <Ordering dataList={res_get_all?.data?.categories} table={"category"} /> })
            } 
          />
        }
        <JumboDemoCard
          title={"Category"}
          wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
          <div style={{ width: '100%' }}>
            <DataTable
              showAutoComplete={true}
              btnTitleAdd="Category"
              relationalObject={location?.state?.relationalObject}
              companyName={location?.state?.company}
              translate={true}
              ddTranslate={false}
              data={res_get_all?.data?.categories}
              bluePrint={bluePrint}
              ViewModel={() => <ViewCategory />}
              ModalContent={() => <Category onClose={hideDialog} />}
              showAddButton={false}
            />
          </div>
        </JumboDemoCard>
      </Box>
    </>
  )
}


export default CategoryList