// Config file

import { decryptStoredData } from 'app/pages/storetoken/StoreTokenCheck';
import { Config } from '../../constant/index';

export default class PP2Service {
  /**
   * @param {string} url
   * @param {string} http_verb
   */

  static async fetch(
    url = "",
    http_verb = "get",
    data = null,
    contentType = "application/json",
    token = true
  ) {
    const storedToken = decryptStoredData(Config.adminApiTokenName);
    const requestOptions = {
      method: http_verb,
      headers: {
        Authorization: "Bearer " + storedToken,
        Accept: "application/json",
        "Content-Type": contentType,
      },
    };

    // console.log("storedToken:", storedToken);

    if (http_verb.toLowerCase() !== "get") {
      requestOptions["body"] = JSON.stringify(data);
    }

    try {
      // console.log("Config.serverApiUrl + url", Config.serverApiUrl + url);
      const response = await fetch(Config.serverApiUrl + url, requestOptions);
      const responseJson = await response.json();
      // console.log('responseJson:', responseJson);
      return responseJson;
    } catch (err) {
      console.log("Some error", err);
      return false;
    }
  }
}
